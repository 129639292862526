<template>
  <div id="cashCollection">
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Transaction") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="6">
          <div class="box ">
            <h4 class="second-title">{{ $t("Select Payment") }}</h4>
            <ul class="payment-methods text-center">
              <li class="text-center disabled">
                <p class="icon" @click="selectPaymentMethod('Visa')">
                  <i class="fas fa-money-check"></i>
                </p>
                <p class="text">{{ $t("Visa") }}</p>
              </li>

              <li class="text-center">
                <p
                  class="icon selectable"
                  :class="reversedObject.type == 'Cash' ? 'active' : ''"
                  @click="selectPaymentMethod('Cash')"
                >
                  <i class="far fa-money-bill-alt"></i>
                </p>
                <p class="text">{{ $t("Cash") }}</p>
              </li>

              <li class="text-center">
                <p
                  class="icon selectable"
                  :class="
                    reversedObject.type == 'Bank Transfer' ? 'active' : ''
                  "
                  @click="selectPaymentMethod('Bank Transfer')"
                >
                  <i class="fas fa-dollar-sign"></i>
                </p>
                <p class="text">{{ $t("Bank Transfer") }}</p>
              </li>

              <li class="text-center">
                <p
                  class="icon selectable"
                  :class="reversedObject.type == 'Check' ? 'active' : ''"
                  @click="selectPaymentMethod('Check')"
                >
                  <i class="far fa-credit-card"></i>
                </p>
                <p class="text">{{ $t("Check") }}</p>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="box">
            <v-row>
              <v-col cols="12" md="6">
                <p class="key">{{ $t("Transaction Id") }}</p>
                <p class="value">{{ reversedObject.transaction_id }}</p>
              </v-col>
              <v-col cols="10" md="6">
                <p class="key">{{ $t("Transaction Date") }}</p>
                <v-text-field
                  id="transaction_date"
                  autocomplete="off"
                  v-model="reversedObject.transaction_date"
                  solo
                  dense
                  :disabled="submitted"
                  @keydown.prevent
                  append-icon="event"
                ></v-text-field>
              </v-col>

              <v-row>
                <v-col cols="12" md="6">
                  <p class="key" v-if="reversedObject.type == 'Cash'">
                    {{ $t("Voucher Number") }}
                  </p>
                  <p class="key" v-if="reversedObject.type == 'Bank Transfer'">
                    {{ $t("Transfer Number") }}
                  </p>
                  <p class="key" v-if="reversedObject.type == 'Check'">
                    {{ $t("Check Number") }}
                  </p>
                  <v-text-field
                    autocomplete="off"
                    v-model="reversedObject.transfer_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="submitted || reversedObject.type == 'Cash'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <p class="key" v-if="reversedObject.type == 'Bank Transfer'">
                    {{ $t("Transfer Date") }}
                  </p>
                  <p class="key" v-if="reversedObject.type == 'Check'">
                    {{ $t("Check Date") }}
                  </p>
                  <v-text-field
                    v-if="
                      reversedObject.type == 'Bank Transfer' ||
                        reversedObject.type == 'Check'
                    "
                    id="transfer_date"
                    autocomplete="off"
                    v-model="reversedObject.transfer_date"
                    solo
                    dense
                    :rules="[checkTransferDate(reversedObject.transfer_date)]"
                    :disabled="submitted"
                    @keydown.prevent
                    append-icon="event"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <h3 class="second-title">{{ $t("Payment Details") }}</h3>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Cash Point")
              }}</label>
              <v-select
                solo
                :items="cash_points"
                v-model="reversedObject.cash_point_id"
                :disabled="submitted"
                item-text="name"
                item-value="id"
                :rules="[validationRules.required]"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Partner")
              }}</label>
              <v-select
                solo
                :items="partners"
                item-text="name"
                item-value="id"
                :rules="[validationRules.required]"
                v-model="reversedObject.partner_id"
                @change="getPartnerCode()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Code")
              }}</label>
              <v-text-field solo v-model="code" disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3"
              ><label for="select payment" class="payment_value">{{
                $t("Tax Registration Number")
              }}</label>
              <v-text-field solo v-model="reversedObject.tax_registration">
              </v-text-field
            ></v-col>
            <v-col cols="12">
              <label for="textarea" class="payment_value">{{
                $t("Notes")
              }}</label>
              <v-textarea
                rows="2"
                v-model="reversedObject.notes"
                row-height="5"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <!-- <hr class="basic" /> -->
    <v-row>
      <v-col cols="12" md="12">
        <h3 class="second-title text-center bold">{{ $t("Reversed From") }}</h3>
      </v-col>
    </v-row>
    <hr class="basic" />
    <v-row>
      <v-col cols="12" md="12" class="info-list">
        <v-row>
          <v-col cols="6" md="3">
            <p class="value">
              <strong>{{ $t("Transaction ID") }}</strong>
            </p>
          </v-col>
          <v-col cols="6" md="3">
            <p class="value">
              <strong>{{ $t("Transaction Date") }}</strong>
            </p>
          </v-col>
          <v-col cols="6" md="3">
            <p class="value">
              <strong>{{ $t("Vendor") }}</strong>
            </p>
          </v-col>
          <!-- <v-col cols="6" md="3" :hidden="paymentObject.type == 'Cash'">
            <p class="value">
              <strong v-if="paymentObject.type == 'Bank Transfer'"
                >Transfer Date</strong
              >
              <strong v-if="paymentObject.type == 'Check'">Check Date</strong>
            </p>
          </v-col> -->
          <v-col cols="6" md="3">
            <p class="value">
              <strong v-if="paymentObject.type == 'Bank Transfer'">{{
                $t("Transfer Number")
              }}</strong>
              <strong v-if="paymentObject.type == 'Check'">{{
                $t("Check Number")
              }}</strong>
              <strong v-if="paymentObject.type == 'Cash'">{{
                $t("Voucher Number")
              }}</strong>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" style="padding-top: 0; margin-top:-15px;">
            <p class="value">
              {{ paymentObject.transaction_id }}
            </p>
          </v-col>
          <v-col cols="6" md="3" style="padding-top: 0; margin-top:-15px;">
            <p class="value">
              {{ paymentObject.transaction_date }}
            </p>
          </v-col>
          <v-col cols="6" md="3" style="padding-top: 0; margin-top:-15px;">
            <p class="value">
              {{ paymentObject.partner_name }}
            </p>
          </v-col>
          <!-- <v-col
            cols="6"
            md="3"
            style="padding-top: 0; margin-top:-15px;"
            :hidden="paymentObject.type == 'Cash'"
          >
            <p class="value">
              {{ paymentObject.transfer_date }}
            </p>
          </v-col> -->
          <v-col cols="6" md="3" style="padding-top: 0; margin-top:-15px;">
            <p class="value">
              {{ paymentObject.transfer_number }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr />
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :options.sync="options"
          :headers="headers"
          :items="paymentObject.items"
          class="elevation-1 level1 "
          loading-text="Loading... Please wait"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
    </v-row>

    <hr class="second" />

    <v-row>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ paymentObject.amount_before_tax }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong>
          {{ paymentObject.tax_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ paymentObject.total_amount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total After Discount") }}: </strong>
          {{ paymentObject.total_after_discount }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-btn color="#7297FF" @click="savePayment">{{
          $t("Save Reverse")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../../mixins/validationMixin";
export default {
  name: "ReverseGeneralPayment",
  data() {
    return {
      valid: false,
      lazy: false,
      submitted: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      headers: [
        {
          text: this.$i18n.t("Code"),
          align: "start",
          sortable: false,
          value: "account_code"
        },
        { text: this.$i18n.t("Name"), value: "account_name", sortable: false },
        { text: this.$i18n.t("Amount"), value: "due_amount", sortable: false },
        { text: this.$i18n.t("Tax"), value: "taxes_names", sortable: false },
        {
          text: this.$i18n.t("Tax Amount"),
          value: "tax_amount",
          sortable: false
        },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        { text: this.$i18n.t("Net"), value: "net_amount", sortable: false }
      ],
      code: "",
      cash_points: [],
      partners: [],
      paymentObject: {
        type: "",
        partner_code: "",
        cash_point_name: "",
        transaction_date: "",
        transaction_id: "",
        transfer_number: "",
        transfer_date: "",
        items: [],
        partner_name: "",
        tax_registration: "",
        notes: "",
        amount_before_tax: "",
        tax_amount: "",
        total_amount: "",
        total_after_discount: ""
      },
      reversedObject: {
        type: "",
        transaction_date: "",
        transaction_id: "",
        transfer_number: "",
        transfer_date: "",
        tax_registration: "",
        notes: ""
      },

      options: {
        itemsPerPage: 100
      },
      id: null
    };
  },
  mixins: [validationMixin],
  methods: {
    getPartnerCode() {
      this.partners.forEach(partner => {
        if (partner.id == this.reversedObject.partner_id) {
          this.reversedObject.tax_registration = partner.tax_registration;
          this.code = partner.code;
        }
      });
    },

    checkTransferDate(value) {
      if (this.paymentObject.type == "Bank Transfer") {
        return value ? true : "Transfer number is required";
      } else if (this.paymentObject.type == "Check") {
        return value ? true : "Check number is required";
      } else {
        return true;
      }
    },
    validateCheckDate(value) {
      if (this.paymentObject.type == "Check") {
        return value ? true : "Transfer number is required";
      } else {
        return true;
      }
    },

    EditTransaction() {
      this.$router.push(
        {
          path: "/editGeneralPayment/" + this.id
        },
        () => {}
      );
    },
    getPaymentData() {
      axios
        .get(this.getApiUrl + "/general_payment/show/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.paymentObject = response.data.data;
        });
    },
    getPaymentDataReversed() {
      axios
        .get(this.getApiUrl + "/general_payment/getPaymentData", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.reversedObject = response.data.data.payment;
          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
        });
    },
    partnersData() {
      axios
        .get(this.getApiUrl + "/partners/getPartnersNotStudentParent", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.partners = response.data.data;
        });
    },
    taxesData() {
      axios
        .get(this.getApiUrl + "/gettaxes/getActiveTaxes", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.taxes = response.data.data;
        });
    },
    getAllAcounts() {
      axios
        .get(this.getApiUrl + "/accounts/getAllAcounts", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.accounts = response.data.data;
        });
    },
    selectPaymentMethod(method) {
      this.$refs.form.resetValidation();
      this.reversedObject.type = method;
      this.reversedObject.cash_point_id = "";
      var _this = this;
      if (
        this.reversedObject.type == "Bank Transfer" ||
        this.reversedObject.type == "Check"
      ) {
        this.reversedObject.transfer_number = "";
        $(document).ready(function() {
          $("#transfer_date").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.reversedObject.transfer_date = dateH;
              } else {
                _this.reversedObject.transfer_date = "";
              }
            }
          });
        });
      }
      if (this.reversedObject.type == "Bank Transfer") {
        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Transfer", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      } else if (this.reversedObject.type == "Check") {
        // this.paymentObject.voucher_number = null;

        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Check", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      } else if (this.reversedObject.type == "Cash") {
        this.reversedObject.transfer_number = null;
        this.reversedObject.transfer_date = null;
        this.getPaymentDataReversed();
        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Cash", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      }
    },
    savePayment() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        axios
          .post(
            this.getApiUrl + "/general_payment/storeReversed/" + this.id,
            this.reversedObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              this.$router.push(
                {
                  path: "/viewReverseGeneralPayment/" + response.data.data.id
                },
                () => {}
              );
            }
          });
      }
    }
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    this.getPaymentData();
    var _this = this;
    $(document).ready(function() {
      $("#transaction_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function(date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.paymentObject.transaction_date = dateH;
          } else {
            _this.paymentObject.transaction_date = "";
          }
        }
      });
    });

    this.selectPaymentMethod("Cash");
    this.getAllAcounts();
    this.partnersData();
    this.taxesData();
    this.getPaymentDataReversed();
  }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}
.payment_value {
  color: #646464 !important;
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}
.bold {
  font-weight: bold;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
